<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Payouts" subtitle="See past and future payouts to your bank account.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no payouts found.">

		<template v-slot:persist>

			<app-content-summary :fixed="true">

				<app-content-summary-item label="Pending" :value="$platform.count.billing.platform.payouts_pending_total | formatMoney" />
				<app-content-summary-item label="Next Payout" :grow="true" :value="nextPayout | localDate('MM/DD/YYYY')" />
				<app-content-summary-item label="Payouts Queued" :value="$platform.count.billing.platform.payouts_queued" />
				<app-content-summary-item label="Total Queued" :value="$platform.count.billing.platform.payouts_queued_total | formatMoney" />
				<app-content-summary-item label="Payouts Paid" :value="$platform.count.billing.platform.payouts_paid" />
				<app-content-summary-item label="Total Paid" :value="$platform.count.billing.platform.payouts_paid_total | formatMoney" />

			</app-content-summary>

		</template>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="Amount" />
				<app-table-column text="Status" />

			</app-table-head>

			<app-table-body>

				<app-table-row :no-click="true" :green="item.status === 1" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY')" />
					<app-table-cell-text :text="item.amount | formatMoney" />
					<app-table-cell-text :text="payoutStatus[item.status]" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			payoutStatus: {
				1: 'Paid',
				2: 'Pending'
			},
			layout: 'auto 120px 120px',
			endpoint: 'billing/payouts',
			live: 'billing/payouts',
			edit: 'Billing.Payouts.Edit'
		}

	},

	computed: {

		nextPayout: function() {

			return this.$platform.count.billing.platform.payouts_next

		}

	}

}

</script>

<style scoped>

</style>